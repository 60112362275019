import axios from "axios";
// import router from "../../router";

export default {
  state: {
    addressSchema: [],
    addresses: [],
    page: 1,
    skip: 0,
    totalpages: 0,
    records: 25,
    totalrecords: 0,
  },
  getters: {
    getAddresses(state) {
      return state.addresses;
    },
    getAddressSchema(state) {
      return state.addressSchema;
    },
    getPage(state) {
      return state.page;
    },
    getTotalPages(state) {
      return state.totalpages;
    },
    getSkip(state) {
      return state.skip;
    },
    getRecords(state) {
      return state.records;
    },
    getTotalRecords(state) {
      return state.totalrecords;
    },
  },
  mutations: {
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    setAddressSchema(state, addressSchema) {
      state.addressSchema = addressSchema;
    },
    setPage(state, page) {
      state.page = page;
    },
    setTotalPages(state, totalpages) {
      state.totalpages = totalpages;
    },
    setSkip(state, skip) {
      state.skip = skip;
    },
    setRecords(state, records) {
      state.records = records;
    },
    setTotalRecords(state, totalrecords) {
      state.totalrecords = totalrecords;
    },
  },
  actions: {
    fetchAddresses(state) {
      state.commit("setAddresses", []);

      var params = new URLSearchParams();
      params.append("token", state.getters.getToken);
      params.append("skip", state.getters.getSkip);
      params.append("limit", state.getters.getRecords);
      let filters = state.getters.getFilterParams;
      for (var filter in filters) {
        params.append(filter, filters[filter]);
      }
      // console.log(params.toString());
      axios
        .get("https://dcklinieken.quantes.nl/api/collections/get/adressen", {
          params: params,
        })
        .then((response) => {
          // console.log(response);
          state.commit("setAddresses", response.data.entries);
          state.commit(
            "setTotalPages",
            Math.ceil(response.data.total / state.getters.getRecords)
          );
          state.commit("setTotalRecords", response.data.total);
        })
        .catch((err) => console.log(err));
    },
    fetchAddressSchema(state) {
      var params = new URLSearchParams();
      params.append("token", state.getters.getToken);

      axios
        .get(
          "https://dcklinieken.quantes.nl/api/collections/collection/adressen",
          {
            params: params,
          }
        )
        .then((response) => {
          // console.log(response);
          state.commit("setAddressSchema", response.data.fields);
        })
        .catch((err) => console.log(err));
    },
    setPage(state, page) {
      state.commit(
        "setSkip",
        page * state.getters.getRecords - state.getters.getRecords
      );
      state.commit("setPage", page);
      state.dispatch("fetchAddresses", null, { root: true });
    },
  },
  modules: {},
};
